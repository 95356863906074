import React from "react"
import sanitizeHtml from 'sanitize-html';
import Img from "gatsby-image";
import sanitizeOptions from "../../helper/sanitizeOptions";
import {normalizePath} from "../../utils/get-url-path";
import AniLink from "gatsby-plugin-transition-link/AniLink";

const AboutClient = ({data}) => {
    const sanitizeOpt = sanitizeOptions();
    const {featuredImage, caseStudySingleFeatured, info, caseStudyRegions, caseStudyIndustries, downloadPdf} = data;

    return (
        <>
            <div className={'case-study-single-info flex-container not-full-width block-space'}>
                {!!info?.brand && (
                    <div className={'content'}>
                        <span className={'paragraph-secondary black'}>
                            Brand:
                        </span>
                        <span className={'h5-title minor-title black'}>
                            {info?.brand}
                        </span>
                    </div>
                )}

                {!!caseStudyIndustries?.nodes && (
                    <div className={'content'}>
                        <span className={'paragraph-secondary black'}>
                            Industries:
                        </span>
                        {caseStudyIndustries.nodes.map((el, i) => i < 1 && (
                                <span className={'h5-title minor-title black'}
                                      key={el.id}>
                                    {el.name}
                                 </span>
                            )
                        )}
                    </div>
                )}
                {!!caseStudyRegions?.nodes.length && (
                    <div className={'content'}>
                        <span className={'paragraph-secondary black'}>
                            Region:
                        </span>
                        {caseStudyRegions.nodes.map((el, i) => i < 1 && (
                                <span className={'h5-title minor-title black'}
                                      key={el.id}>
                                    {el.name}
                                 </span>
                            )
                        )}
                    </div>
                )}

                {!!caseStudySingleFeatured?.additionalFields?.clientUrl && (
                    <div className={'content'}>
                        <span className={'paragraph-secondary black'}>
                            Client’s URL:
                        </span>
                        <div className={'h5-title minor-title black'}
                             dangerouslySetInnerHTML={{__html: sanitizeHtml(caseStudySingleFeatured.additionalFields.clientUrl, sanitizeOpt.title)}}/>
                    </div>
                )}

            </div>
            {!!downloadPdf?.uri && (
                <div className={'not-full-width block-space'}>
                    <AniLink to={downloadPdf?.mediaItemUrl}
                             paintDrip
                             duration={1}
                             hex="#ed5d2b"
                             className={'download-pdf'}
                             target="_blank"
                    >
                        {'Download case in pdf'}
                    </AniLink>
                </div>
            )}

            {!!featuredImage?.node?.remoteFile?.childImageSharp && (
                <div>
                    <Img fluid={featuredImage.node.remoteFile.childImageSharp.fluid}/>
                </div>
            )}

            {info?.aboutClient && (
                <div className={'not-full-width block-space'}>
                    <span className={'h4-title-bold-services'}>About client</span>
                    <div dangerouslySetInnerHTML={{__html: sanitizeHtml(info.aboutClient, sanitizeOpt.title)}}/>
                </div>
            )}

            <div className={'about-client'}>
                {caseStudySingleFeatured?.additionalFields?.clientFigures && (
                    <div className={'figures'}
                         dangerouslySetInnerHTML={{__html: sanitizeHtml(caseStudySingleFeatured.additionalFields.clientFigures, sanitizeOpt.title)}}/>
                )}
            </div>
        </>
    )
}

export default AboutClient