import React from "react"
import { graphql } from "gatsby"
import CaseStudyPart from "../../components/template-parts/case-study-part"

export default ({ data }) => <CaseStudyPart data={data}/>

export const query = graphql`
    query caseStudy($id: String!) {
        caseStudy: wpCaseStudy(id: { eq: $id }) {
            id
            title
            content
            tags {
                nodes {
                    id
                    name
                }
            }
            featuredImage {
                node {
                    sourceUrl
                    remoteFile {
                        childImageSharp {
                            fluid(quality: 100) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
            caseStudyRegions {
                nodes {
                    id
                    name
                }
            }
            caseStudyTechnologies {
                nodes {
                    id
                    name
                }
            }
            caseStudyIndustries { 
                nodes {
                    id
                    name
                }
            }
            caseStudySingleFeatured {
                additionalFields {
                    titleH5
                    clientFigures
                    clientUrl
                }
                titlesList {
                    titleH2
                    titleH5
                }
                caseStudiesServices {
                    ... on WpService {
                        title
                        uri
                        id
                    }
                }
                downloadPdf {
                    uri
                    mediaItemUrl
                }
                contentBlocks {
                    content
                    fieldGroupName
                }
                caseStudiesPlatforma
            }
            info {
                brand
                aboutClient
                aboutClientShort
                result
            }
            seo {
                title
                metaDesc
                canonical
            }
            additionalMetaTag {
                keywords
                ogDescription
                ogTitle
                ogType
                ogImage {
                    mediaItemUrl
                }
                twitterImage{
                    mediaItemUrl
                }
                twitterTitle
                twitterDescription
            }
        }

        customFields: wpPage(slug: {eq: "case-studies"}) {
            caseStudiesCustomFields {
                caseStudyBreadcrumbs {
                    crumblabel
                    pathname
                }
            }
        }

        allCaseStudy: allWpCaseStudy(
            limit: 4
            sort: { fields: date, order: DESC }
            filter: {id: {ne: $id}}
        ) {
            nodes {
                id
                slug
                title
                content
                link
                contentType {
                    node {
                        graphqlSingleName
                        name
                    }
                }
                caseStudySingleFeatured {
                    additionalFields {
                        titleH5
                        clientFigures
                    }
                }
                caseStudyRegions {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyIndustries {
                    nodes {
                        id
                        name
                    }
                }
                caseStudyTechnologies {
                    nodes {
                        id
                        name
                    }
                }
                info {
                    aboutClient
                    aboutClientShort
                    brand
                    result
                    gridImage {
                        remoteFile {
                            childImageSharp {
                                fluid(quality: 70) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        title
                    }
                }
            }
        }

        testimonials: allWpTestimonial(
            sort: { fields: date, order: DESC }
        ) {
            nodes {
                id
                testimonials {
                    authorName
                    authorPosition
                    testimonial
                }
                testimonialsCustomFields {
                    caseStudy {
                        ... on WpCaseStudy {
                            id
                            uri
                        }
                    }
                    events {
                        ... on WpEvent {
                            id
                            uri
                        }
                    }
                }
            }
        }
    }
`
