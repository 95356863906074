import React, { useEffect, useState } from "react"
import Slider from 'react-slick';
import Item from "./Item";

const TestimonialsBlock = ({
                               testimonialsItems,
                               testimonialsCustomFields,
                               id,
                               classWrapper,
                               carouselSettings,
                               isVisible
                           }) => {
    const [animateContent, setAnimateContent] = useState(false);
    let caseStudyArray = !!id && testimonialsItems.filter((el) => (!!el?.testimonialsCustomFields?.caseStudy && el.testimonialsCustomFields.caseStudy.id === id));
    let resultArray = Array.isArray(caseStudyArray) ? caseStudyArray : testimonialsItems;
    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 1000,
        autoplaySpeed: 3000,
        fadeIn: true,
        autoplay: false,
        pauseOnHover: false,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        setAnimateContent(isVisible);
    }, [isVisible]);

    return (
        <>
            {Array.isArray(resultArray) && !!resultArray.length && (
                <div className={`testimonials-container ${!!classWrapper ? classWrapper : ''}`}>
                    {!!id && (
                        <h4 className={'h4-title'}>Testimonials</h4>
                    )}
                    <ul
                        className={'content'}
                        style={{
                            transition: 'all .75s ease',
                            opacity: `${animateContent ? 1 : 0}`,
                        }}
                    >
                        <Slider {...(!!carouselSettings ? carouselSettings : settings)}>
                            {Array.isArray(resultArray) && resultArray.map((page) => (
                                page && <Item
                                    key={page.id}
                                    page={page}
                                    testimonialsCustomFields={testimonialsCustomFields}
                                />
                            ))}
                        </Slider>
                    </ul>
                </div>
            )}
        </>
    )
}

export default TestimonialsBlock

