import React from "react"
import sanitizeHtml from 'sanitize-html';
import Layout from "../../components/layout"
import AboutClient from "../case-study/about-client"
import Tags from "../tags"
import TitleLists from "../title-lists";
import TestimonialsBlock from "../testimonials";
import { globalHistory as history } from '@reach/router';
import sanitizeOptions from "../../helper/sanitizeOptions";
import ClientsImgItems from "../testimonials-clients-img-list/client-img-items";
import {Helmet} from "react-helmet";

function CaseStudyPart({
                           data: {
                               caseStudy: {
                                   id,
                                   title,
                                   content,
                                   caseStudySingleFeatured,
                                   info,
                                   featuredImage,
                                   tags,
                                   caseStudyRegions,
                                   caseStudyTechnologies,
                                   caseStudyIndustries,
                                   seo,
                                   additionalMetaTag
                               }, allCaseStudy, customFields, testimonials
                           }
                       }) {
    const sanitizeOpt = sanitizeOptions();
    const caseStudies = allCaseStudy;
    const { titlesList, caseStudiesServices, caseStudiesPlatforma, downloadPdf, contentBlocks} = caseStudySingleFeatured;
    const { titleH2, titleH5 } = !!titlesList && titlesList;
    // Set breadcrumbs
    const { location } = history;
    const getCrumbs = Array.isArray(customFields?.caseStudiesCustomFields?.caseStudyBreadcrumbs) && customFields.caseStudiesCustomFields.caseStudyBreadcrumbs;
    const LastCrumb = {
        crumblabel: title,
        pathname: location.pathname
    };

    const crumbs = [...getCrumbs, LastCrumb];
    // Set metadata
    const seoData = { ...{ seo }, additionalMetaTag, title };
    let caseStudyArray = Array.isArray(testimonials?.nodes) && testimonials?.nodes.filter((el) => (!el?.testimonialsCustomFields?.events));

    return (
        <Layout
            setClass={'case-study-single'}
            seoData={seoData}>
            <Helmet
                bodyAttributes={{
                    class: 'dark-header case-study-single-page'
                }}
            />
            <div className={'page-content case-study-single'}>
                <div className={'not-full-width'}>
                    {!!caseStudyTechnologies?.nodes.length && caseStudyTechnologies.nodes.map((el, i) => i < 1 && (
                        <h5 key={el.id}
                            className="h5-title body-font-styles">
                            {el.name}
                        </h5>
                    ))}
                    <h1 className={'h3-title-services black block-space'}>
                        {title}
                    </h1>
                </div>

                <AboutClient data={{ caseStudySingleFeatured, info, featuredImage, caseStudyRegions, caseStudyIndustries, downloadPdf }}/>

                {!!caseStudiesPlatforma &&
                    <div className={'case-studies__services-wrapper flex-container full-content-block block-space'}>
                        <div className={'case-studies__platforma'}>
                            <h6 className="h6-title">Platforma</h6>
                            <p>{caseStudiesPlatforma}</p>
                        </div>
                    </div>
               }

                {content && (
                    <div className={'page-content-post'}
                         dangerouslySetInnerHTML={{ __html: sanitizeHtml(content, sanitizeOpt.contentData) }}/>
                )}

                {Array.isArray(caseStudiesServices) &&
                    caseStudiesServices.length &&
                    <div className={'case-studies__services not-full-width'}>
                        <h4 className={'h4-title-bold-services minor-title black'}>Services provided</h4>
                        <Tags
                            data={caseStudiesServices}
                        />
                    </div>
                }

                <TestimonialsBlock
                    testimonialsItems={caseStudyArray}
                    id={id}
                    isVisible={true}
                    classWrapper={'case-study-single-testimonials'}/>

                {contentBlocks?.map((el, i) => (
                    <div className={'page-content-post'}
                         key={i}
                         dangerouslySetInnerHTML={{ __html: sanitizeHtml(el.content, sanitizeOpt.contentData) }}/>
                ))}

                {!!info?.result &&
                    <div className={'result-implementations flex-container-nowrap'}>
                        <h4 className={'h4-title black'}>Result</h4>
                        <div className={'result'}>
                            <p className={'h5-title black'}>
                                {info?.result}
                            </p>
                        </div>
                        <div className={'implementations'}>
                            <h5 className={'h5-title black'}>Implementations</h5>
                            <Tags data={tags?.nodes}/>
                        </div>
                    </div>
                }
            </div>

            <div className="page-content case-studies-block-container">
                <TitleLists
                    data={{
                        customFields: 'case_studies_block',
                        caseStudies: caseStudies,
                        titleH2: titleH2,
                        titleH5: titleH5,
                        link: 'case-studies',
                    }}/>
            </div>
        </Layout>
    )
}

export default CaseStudyPart
