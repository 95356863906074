import React from "react";
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { normalizePath } from "../../utils/get-url-path";
import useWindowSize from "../../hooks/useWindowSize";
import Img from "gatsby-image";

const Items = ({ page, testimonialsCustomFields }) => {
    const size = useWindowSize();
    const imageWeb = page?.testimonials?.image?.remoteFile?.childImageSharp?.fluid;

    return (
        <>
            <li key={page.id}
                className={`project-item ${!!page?.slug ? page.slug : ''}`}>
                {!!imageWeb &&
                !page?.testimonialsCustomFields?.events &&
                (size.width < 768) && !!page?.testimonials?.image?.remoteFile?.childImageSharp && (
                    <div className={'mobile-wrapper'}>
                        <Img className={'image'}
                             fluid={page.testimonials.image.remoteFile.childImageSharp.fluid}/>
                    </div>
                )}
                <h5 className={'h5-title black" data-animated-group="animate-description'}>
                    {page?.testimonials?.testimonial}
                </h5>
                <div className={'name-position-wrapper'}>
                    {!!imageWeb &&
                        !!page?.testimonialsCustomFields?.events &&
                    <Img className={'image-icon'}
                         fluid={page.testimonials.image.remoteFile.childImageSharp.fluid}/>}
                    <div>
                        <h4 className={'h4-title black testimonial-name'}>{page?.testimonials?.authorName}</h4>
                        <p className={'text paragraph-secondary black testimonial-company'}>
                            {page?.testimonials?.authorPosition}
                        </p>
                    </div>
                </div>
                {!!testimonialsCustomFields && !!page?.testimonialsCustomFields?.caseStudy && (
                    <AniLink
                        paintDrip
                        duration={1}
                        hex="#ed5d2b"
                        to={normalizePath(page.testimonialsCustomFields.caseStudy.uri)}
                        className={'transition-link paragraph-primary__bold possibilities-link mc-link mc-button-secondary right eye-icon'}>
                        {testimonialsCustomFields.button}
                    </AniLink>
                )}
            </li>
        </>
    )
}

export default Items